import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RefreshCw } from 'lucide-react';

const BASE_SNAKE_SPEED = 100;
const MAX_SNAKE_SPEED = 50;

// Sound generation functions
const createAudioContext = () => {
  return new (window.AudioContext || window.webkitAudioContext)();
};

const playEatSound = (audioContext) => {
  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();
  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // A4 note
  oscillator.frequency.exponentialRampToValueAtTime(880, audioContext.currentTime + 0.1); // Ramp to A5

  gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.currentTime + 0.1);

  oscillator.start();
  oscillator.stop(audioContext.currentTime + 0.1);
};

const playGameOverSound = (audioContext) => {
  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();
  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.type = 'sawtooth';
  oscillator.frequency.setValueAtTime(220, audioContext.currentTime); // A3 note
  oscillator.frequency.exponentialRampToValueAtTime(55, audioContext.currentTime + 0.5); // Ramp down to A1

  gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(0.01, audioContext.currentTime + 0.5);

  oscillator.start();
  oscillator.stop(audioContext.currentTime + 0.5);
};

const SnakeGame = ({ isActive, setIsActive }) => {
  const [snake, setSnake] = useState([{ x: 10, y: 10 }]);
  const [food, setFood] = useState({ x: 15, y: 15 });
  const [direction, setDirection] = useState('RIGHT');
  const [score, setScore] = useState(0);
  const [gridSize, setGridSize] = useState({ width: 0, height: 0 });
  const [gameOver, setGameOver] = useState(false);
  const speedRef = useRef(BASE_SNAKE_SPEED);
  const audioContextRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = createAudioContext();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const resetGame = () => {
    setSnake([{ x: 10, y: 10 }]);
    setFood({ x: 15, y: 15 });
    setDirection('RIGHT');
    setScore(0);
    setGameOver(false);
    speedRef.current = BASE_SNAKE_SPEED;
  };

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    const newSnake = [...snake];
    const head = { ...newSnake[0] };

    switch (direction) {
      case 'UP': head.y--; break;
      case 'DOWN': head.y++; break;
      case 'LEFT': head.x--; break;
      case 'RIGHT': head.x++; break;
    }

    // Check for collisions with borders
    if (head.x < 0 || head.x >= gridSize.width || head.y < 0 || head.y >= gridSize.height) {
      setGameOver(true);
      playGameOverSound(audioContextRef.current);
      return;
    }

    // Check for collisions with self
    if (newSnake.some(segment => segment.x === head.x && segment.y === head.y)) {
      setGameOver(true);
      playGameOverSound(audioContextRef.current);
      return;
    }

    newSnake.unshift(head);

    if (head.x === food.x && head.y === food.y) {
      playEatSound(audioContextRef.current);
      setScore(prevScore => {
        const newScore = prevScore + 1;
        // Increase speed based on score
        speedRef.current = Math.max(MAX_SNAKE_SPEED, BASE_SNAKE_SPEED - (newScore * 5));
        return newScore;
      });
      setFood({
        x: Math.floor(Math.random() * gridSize.width),
        y: Math.floor(Math.random() * gridSize.height),
      });
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  }, [snake, direction, food, gridSize, gameOver]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (gameOver) return;
      switch (e.key) {
        case 'ArrowUp': setDirection(prev => prev !== 'DOWN' ? 'UP' : prev); break;
        case 'ArrowDown': setDirection(prev => prev !== 'UP' ? 'DOWN' : prev); break;
        case 'ArrowLeft': setDirection(prev => prev !== 'RIGHT' ? 'LEFT' : prev); break;
        case 'ArrowRight': setDirection(prev => prev !== 'LEFT' ? 'RIGHT' : prev); break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [gameOver]);

  useEffect(() => {
    if (isActive && !gameOver) {
      const gameLoop = setInterval(moveSnake, speedRef.current);
      return () => clearInterval(gameLoop);
    }
  }, [isActive, moveSnake, gameOver]);

  useEffect(() => {
    const updateGridSize = () => {
      setGridSize({
        width: Math.floor(window.innerWidth / 20),
        height: Math.floor(window.innerHeight / 20),
      });
    };

    updateGridSize();
    window.addEventListener('resize', updateGridSize);

    return () => {
      window.removeEventListener('resize', updateGridSize);
    };
  }, []);

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 z-50 pointer-events-none">
      {snake.map((segment, index) => (
        <div
          key={index}
          className="absolute bg-green-500 rounded-sm"
          style={{
            left: `${segment.x * 20}px`,
            top: `${segment.y * 20}px`,
            width: '18px',
            height: '18px',
          }}
        />
      ))}
      <div
        className="absolute bg-red-500 rounded-sm"
        style={{
          left: `${food.x * 20}px`,
          top: `${food.y * 20}px`,
          width: '18px',
          height: '18px',
        }}
      />
      <div className="absolute top-4 right-4 bg-white bg-opacity-75 p-2 rounded">
        Score: {score}
      </div>
      {gameOver && (
        <div className="absolute inset-0 flex items-center justify-center pointer-events-auto">
          <div className="bg-black bg-opacity-90 text-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-4xl font-bold mb-4">Game Over</h2>
            <div className="text-6xl font-bold mb-6">{score}</div>
            <div className="text-xl mb-6">Final Score</div>
            <button
              onClick={resetGame}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-200"
            >
              <RefreshCw className="mr-2" size={20} />
              Play Again
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SnakeGame;