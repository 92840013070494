import React, { useState, useEffect } from 'react';
import { Github, Star, Dot } from 'lucide-react';
import SnakeGame from './components/SnakeGame';

const projects = [
  { id: 1, name: 'Mantis', description: 'Automated Discovery, Recon & Scan', link: 'https://github.com/PhonePe/mantis', stars: '809' },
  { id: 2, name: 'Mustang', description: 'Boolean expression indexer', link: 'https://github.com/PhonePe/mustang', stars: '9' },,
  { id: 3, name: 'Drove', description: 'Distributed container orchestrator', link: 'https://github.com/PhonePe/drove', stars: '2' },,
  { id: 4, name: 'Pulse', description: 'Data that powers the PhonePe Pulse website', link: 'https://github.com/PhonePe/pulse', stars: '181' },,
  { id: 5, name: 'DLM', description: 'Distributed Lock Manager', link: 'https://github.com/PhonePe/dlm', stars: '1' },,
  { id: 6, name: 'Epoch', description: 'Task scheduler on Drove', link: 'https://github.com/PhonePe/epoch', stars: '1' },,
];

const ProjectTile = ({ project }) => (
  <div className="bg-white bg-opacity-90 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ">
    <h3 className="text-xl font-bold mb-2">{project.name}</h3>
    <p className="text-gray-800 mb-4">{project.description}</p>
    <a
      href={project.link}
      className="inline-flex items-center text-blue-600 hover:text-blue-800"
    >
      <Github className="mr-1" size={16}/>Code
      <Dot></Dot>
      <Star className="mr-1" size={16} />{project.stars}
    </a>
  </div>
);

const GridBackground = () => (
  <div className="absolute inset-0 bg-opacity-10 bg-white">
    <div className="absolute inset-0" style={{
      backgroundImage: `
        linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px)
      `,
      backgroundSize: '20px 20px'
    }} />
  </div>
);

const AnimatedGradientBackground = () => (
  <div 
    className="absolute inset-0 z-0"
    style={{
      background: 'linear-gradient(-45deg, #5A0094, #00A4FF, #8D11E3, #0050B2)',
      backgroundSize: '400% 400%',
      animation: 'gradient 60s ease infinite',
    }}
  >
    <style>{`
      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }
        50% {
          background-position: 100% 50%;
        }
        100% {
          background-position: 0% 50%;
        }
      }
    `}</style>
  </div>
);

const Header = () => (
  <header className="relative z-10 py-8 mb-12">
    <div className="container mx-auto px-4">
      <h1 className="text-5xl font-extrabold text-center text-white">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500" style={{ opacity: 0.9 }}>
          PhonePe Open Source
        </span>
      </h1>
      <div className="w-24 h-1 bg-gradient-to-r from-purple-400 to-blue-500 mx-auto mt-4 rounded-full"></div>
    </div>
  </header>
);

function App() {
  const [isGameActive, setIsGameActive] = useState(false);
  const [keySequence, setKeySequence] = useState('');
  
  useEffect(() => {
    const handleKeyDown = (e) => {
      setKeySequence(prev => {
        const newSequence = (prev + e.key).toLowerCase().slice(-5);
        if (newSequence === 'snake') {
          setIsGameActive(true);
          return '';
        }
        return newSequence;
      });
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden">
      <AnimatedGradientBackground />
      <GridBackground />
      
      {/* Content */}
      <div className="relative z-10">
        <Header />
        <div className="container mx-auto px-4">
          <div 
            className="grid gap-8" 
            style={{
              '--min-column-width': '250px',
              '--column-count': '3',
              gridTemplateColumns: 'repeat(var(--column-count), minmax(var(--min-column-width), 1fr))'
            }}
          >
            {projects.map((project) => (
              <div key={project.id} className="project-tile">
                <ProjectTile project={project} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Snake Game */}
      <SnakeGame isActive={isGameActive} setIsActive={setIsGameActive} />
    </div>
  );
}

export default App;